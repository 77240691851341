import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";

import Wrapper from './Wrapper.jsx';
import APN from './APN/Post.jsx';
import B2B from './B2B/Post.jsx';
import Reconhecimento from './Reconhecimento/Post.jsx';
import boasvindas from './boasvindas/Post.jsx';

class Main extends Component
{
    render()
    {
        return(
            <Wrapper>
                <Router>
                    <Switch>
                        <Route exact path='/' component={APN} />
                        <Route exact path='/apn' component={APN} />
                        <Route exact path='/reconhecimento' component={Reconhecimento} />
                    </Switch>
                </Router>
            </Wrapper>
        )
    }
}

export default Main;