export default {
    weekdays: [
        { label: 'Selecione', value: '' },
        { label: 'Domingo', value: 'Domingo' },
        { label: 'Segunda', value: 'Segunda' },
        { label: 'Terça', value: 'Terça' },
        { label: 'Quarta', value: 'Quarta' },
        { label: 'Quinta', value: 'Quinta' },
        { label: 'Sexta', value: 'Sexta' },
        { label: 'Sábado', value: 'Sábado' }
    ],
    typesAPN: [
        { label: 'Uma foto', value: 1 },
        //{ label: 'Duas fotos', value: 2 },
        //{ label: 'Foto da Cidade', value: 3 },
        { label: 'Padrão', value: 4 }
    ],
    typesB2B: [
        { label: 'Uma foto', value: 1 },
        { label: 'Duas fotos', value: 2 },
        { label: 'Padrão', value: 3 }
    ],
    typesRec: [
        //{ label: 'Sem foto', value: 'A' },
        { label: 'Com foto', value: 'B' },
    ],
    modelsRec: [
        { label: 'Bronze', value: 'bronze' },
        { label: 'Prata', value: 'prata' },
        { label: 'Ouro', value: 'ouro' },
        { label: 'Rubi', value: 'rubi' },
        { label: 'Diamante', value: 'diamante' },
        { label: 'Duplo Diamante', value: 'duplo' },
        { label: 'Triplo Diamante', value: 'blue' },
        { label: 'Diamante Red', value: 'red' },
        { label: 'Diamante Black', value: 'black' },
        { label: 'Embaixador', value: 'embaixador' },
    ],
    getLabelModelRec: function(value)
    {
        return this.modelsRec.find(item => value === item.value )
    }
}